import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";

import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";

class CheckCheckList extends HFormUser {

    componentDidMount() { this.readApi(); }
    readApi() {
        this.post(Api.checkCheckList)
            .then(data => { this.stateHarrys(this, data) })
    }
    btEdit = row => {
        apiRuta.toRutaAdd(this, "Tree", Api.frontCheckObjList, { detKey: row })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "codigo", label: "Código", align: "left" }
            , { id: "descripcion", label: "Descripción", align: "left" }
            , { id: "tip_codigo", label: "Objeto", align: "left" }
            , { id: "bt_Asig", label: "Check", align: "center", accion: this.btEdit, clsIcono: "fas fa-check-square" }
        ]
        return (<ContainerRightTable
            tituloContainer='Tipos CheckList'
            tituloTable='CheckList'
            titulos={titulos}
            resObj={this.state.resObj}
            refresh={this.startRefresh}
        />
        );
        //reCrear={this.startReCrear}
    }

}
export default WithNavigate(CheckCheckList);