import React,{Component} from "react";
import { Route } from "react-router-dom";
import Api  from "modCheckList/ApiCheckList";
//import Route from "global/auth/Route";
import HMainRouter  from "global/components/Page/MenuLTE/HMainRouter";

import Home from "Home";

import TabCheckList from "modCheckList/manten/TabCheckList";
import TabCheckListTab from "modCheckList/manten/TabCheckListTab";
import TabCheckListTabEdit from "modCheckList/manten/TabCheckListTabEdit";

import CheckCheckList from "modCheckList/manten/CheckCheckList";

import CheckObjChActList from "modCheckList/manten/CheckObjChActList";
import CheckObjChAllList from "modCheckList/manten/CheckObjChAllList";
import CheckObjList from "modCheckList/manten/CheckObjList";
import CheckObjEdit from "modCheckList/manten/CheckObjEdit";
import CheckObjTreeA from "modCheckList/manten/CheckObjTreeA";
import CheckObjTreeB from "modCheckList/manten/CheckObjTreeB";
import CheckListDat from "modCheckList/manten/CheckListDat";
import CheckListDatChk from "modCheckList/manten/CheckListDatChk";

import CheckFileLoad from "modCheckList/manten/CheckFileLoad";
import CheckFileList from "modCheckList/manten/CheckFileList";

class MainRouterMod  extends Component{
   render() {
      return (
         <HMainRouter>
      <Route exact path={Api.checkGenTabHelpList}    element={<TabCheckList/>} />
      <Route exact path={Api.checkGenTabHelpListTab}    element={<TabCheckListTab/>} />
      <Route exact path={Api.checkGenTabHelpListTabEdit}    element={<TabCheckListTabEdit/>} />

      <Route exact path={Api.checkCheckListRoute}    element={<CheckCheckList/>} />
      <Route exact path={Api.checkObjChAllListRoute}    element={<CheckObjChAllList/>} />
      <Route exact path={Api.checkObjChActListRoute}    element={<CheckObjChActList/>} />
      <Route exact path={Api.checkObjListRoute}    element={<CheckObjList/>} />
      <Route exact path={Api.checkObjEditRoute}    element={<CheckObjEdit/>} />
      <Route exact path={Api.checkObjTreeARoute}    element={<CheckObjTreeA/>} />
      <Route exact path={Api.checkObjTreeBRoute}    element={<CheckObjTreeB/>} />
      <Route exact path={Api.frontCheckListDat}    element={<CheckListDat/>} />
      <Route exact path={Api.frontCheckListDatChk}    element={<CheckListDatChk/>} />

      <Route exact path="/CheckList/CheckFileLoad/BT1"    element={<CheckFileLoad/>} />
      <Route exact path="/CheckList/CheckFileLoad/AT1"    element={<CheckFileLoad/>} />
      <Route exact path="/CheckList/CheckFileList/"    element={<CheckFileList/>} />

      <Route exact path="/" element={<Home/>} />
      </HMainRouter>
         );
   }
}
export default MainRouterMod;