import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";
import HBoxTextArea from "global/components/Page/InputHtml/HBoxTextArea";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";

class CheckListDat extends HFormUser {


    componentDidMount() { this.readApi(); }
    readApi() {
        //this.post(Api.checkLisDat,{idKey:this.props.location.state.detKey})
        //		.then(data=>{this.stateHarrys(this,data)})     
        this.post(Api.checkObjChEdit, { regKey: this.props.location.state.detKey })
            .then(data => {//console.log(data);
                this.setState({
                    dataCam: data.obj.daCam
                    , dataObj: data.obj.daObjCh
                    , dataChk: data.obj.daChk
                    , dataEst: data.obj.estIns
                    , loading: false
                    , resObj: { rows: data.obj.tabHijas }
                    , resTab: { rows: data.obj }
                    , tabHelp: data.obj.help
                    , tabPrg: data.obj.regPrg
                })
            })
    }
    ayudaHelp=(stTabla)=>{
        console.log("tab=====",stTabla)
       
        // Object.keys(this.state.tabHelp).map((key, index) => {
        //     console.log(index,key,this.state.tabHelp[key])
        //     console.log("help_"+ stTabla, key,"help_"+ stTabla === key)
        //     if ("help_"+ stTabla === key)
        //         return this.state.tabHelp[key]
        // })

        for (const val in this.state.tabHelp) {
            //console.log(val,` = ${this.state.tabHelp[val]}`);
            //console.log(val)
            if ("help_"+ stTabla === val)
                return this.state.tabHelp[val]
        }
        return this.state.dataEst
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        //    if (this.state.swError) return (<ShowError msg={this.state.msg}/>)
        //console.log("data" , this.state.data)
        //console.log("help",this.state.tabHelp)
        return (
            <div>
                <ContainerRightEdit
                    tituloContainer={"xx" + this.props.location.state.detKey}
                >
                    <form className="form-horizontal well" id="forms-registro">
                        <fieldset>
                            <HBoxTextCod
                                disabled
                                label="Num.Evento."
                                id="txNumEvento"
                                title="Número de CheckList"
                                value={this.state.dataObj && this.state.dataObj.numevento}
                            />
                            <HBoxTextCod
                                disabled
                                label="Fecha Programación"
                                id="txFProgra"
                                title="Fecha Programación"
                                value={this.state.dataObj && this.state.dataObj.fprogra}
                            />
                            <HBoxText
                                disabled
                                label="Tipo de Checklist"
                                id="txCodCheck"
                                title="Tipo de Checklist"
                                value={this.state.dataObj && (this.state.dataObj.codchk + "/" + this.state.dataObj.codchk_descrip)}
                                style={{ width: "390px" }}
                                maxLength="20"
                            />
                            <HBoxSelect
                                label="Estado de la Inspección"
                                id="cbEstInsp"
                                title="Estado de la Inspección"
                                value={this.state.dataObj && this.state.dataObj.ins_estado}
                                option={this.state.dataEst}
                            />


                            {this.state.dataCam.map((val, iCol) => {
                                if (val.codtipobj === "TAB")
                                    return (<HBoxSelect
                                        key={"ckx" + val.codigo}
                                        label={val.nombre}
                                        id={"cb_" + val.codigo}
                                        title={val.descripcion}
                                        maxLength={val.largo}
                                        value={val.valor}
                                        size={val.largo}
                                        option={this.ayudaHelp(val.tabla)}
                                    />
                                    )

                                if (val.codtipobj === "TEXT")
                                    return (<HBoxTextArea
                                        key={"ckx" + val.codigo}
                                        label={val.nombre}
                                        id={"cb_" + val.codigo}
                                        title={val.descripcion}
                                        maxLength={val.largo}
                                        value={val.valor}
                                        size={val.largo}
                                    />
                                    )

                                if (val.codtipobj === "NUM")
                                    return (<HBoxTextNum
                                        key={"ckx" + val.codigo}
                                        label={val.nombre}
                                        id={"cb_" + val.codigo}
                                        title={val.descripcion}
                                        maxLength={val.largo}
                                        value={val.valor}
                                        size={val.largo}
                                    />
                                    )
                                if (val.codtipobj === "FECHA")
                                    return (<HBoxTextArea
                                        key={"ckx" + val.codigo}
                                        label={val.nombre}
                                        id={"cb_" + val.codigo}
                                        title={val.descripcion}
                                        maxLength={val.largo}
                                        value={val.valor}
                                        size={val.largo}
                                    />
                                    )

                                return (<HBoxTextCod
                                    disabled1
                                    key={"ckx" + val.codigo}
                                    label={val.nombre}
                                    id={"tx_" + val.codigo}
                                    title={val.descripcion}
                                    maxLength={val.largo}
                                    value={val.valor}
                                    size={val.largo}
                                />
                                )
                            })
                            }


                            <div className="form-group" style={{ width: "200px", visibility: "hidden" }}>
                                <label className="col-sm-3 control-label" >id Sistema</label>
                                <div className="col-sm-3">
                                    <input type="text"
                                        className="input-xxlarge required"
                                        disabled
                                        id="codigo"
                                        name="codigo"
                                        value={this.state.data && this.state.data.name}
                                        title="Id Sistema"
                                        pattern="[a-z0-9._%+-]+.[a-z0-9.-]+\.[a-z]{2,3}$"
                                        minength="5"
                                        maxLength="10"
                                    />
                                </div>
                            </div>

                        </fieldset>
                    </form>
                </ContainerRightEdit>
            </div>
        );
    }
    //                resObj={this.state.resObj}

}
export default WithNavigate(CheckListDat);