import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";
import HBoxTextArea from "global/components/Page/InputHtml/HBoxTextArea";

class TabCheckListTab extends HFormUser {

    componentDidMount() { this.readApi(); }
    handleChange = name => event => {
        this.setState({ error: "" });
    };
    getKey = () => {
        return {
            idKey: this.props.location.state.detKey
            , idHelp: this.props.location.state.detHelp
        }
    }
    readApi() {
        this.post(Api.checkListTablaEdit, this.getKey())
            .then(data => { this.stateHarrys(this, data) })
    }
    actualizarApi() {
        var stData = {
              idKey: this.props.location.state.detKey
            , idHelp: this.props.location.state.detHelp
            , codigo: document.getElementById("txCodigo").value
            , descrip: document.getElementById("txDescrip").value
        }
        this.post(Api.checkListTablaAct, stData)
            .then(data => { if (data.ok) apiRuta.toRutaBack(this) })
    }
    eliminarApi() {
        this.post(Api.checkListTablaEli, this.getKey())
            .then(data => {
                alert(data.msg)
                if (data.ok) apiRuta.toRutaBack(this)
            })
    }
   

    render() {
        if (this.state.loading) return (<ShowLoading />)
        return (<ContainerRightEdit
            tituloContainer={this.state.data && (this.state.data.codigo + '/' + this.state.data.descrip)}
        >
            <form className="form-horizontal well" id="forms-registro">
                <fieldset>
                    <HBoxTextCod
                        disabled
                        label="id Sistema"
                        id="txId"
                        title="Código de la Sistema"
                        value={this.state.data && this.state.data.name}
                    />
                    <HBoxTextCod
                        label="Código"
                        id="txCodigo"
                        title="Código del Sistema"
                        value={this.state.data && this.state.data.codigo}
                    />

                    <HBoxTextArea
                        label="Descripción"
                        id="txDescrip"
                        title="Descripción del Sistema"
                        value={this.state.data && this.state.data.descrip}
                        maxLength="100"
                    />

                    <div className="form-group" style={{ width: "200px", visibility: "hidden" }}>
                        <label className="col-sm-3 control-label" >id Sistema</label>
                        <div className="col-sm-3">
                            <input type="text"
                                className="input-xxlarge required"
                                disabled
                                id="codigo"
                                name="codigo"
                                value={this.state.data && this.state.data.name}
                                title="Id Sistema"
                                pattern="[a-z0-9._%+-]+.[a-z0-9.-]+\.[a-z]{2,3}$"
                                minength="5"
                                maxLength="10"
                            />
                        </div>
                    </div>


                    <HBoxButtonCrud
                        btLeerClick={() => this.readApi()}
                        btActualizarClick={() => this.actualizarApi()}
                        btEliminarClick={() => this.eliminarApi()}
                    />
                </fieldset>
            </form>
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(TabCheckListTab);