import React, { Component } from 'react';
class HTextArea extends Component {
  constructor(props) {
    super(props);
    this.data = props;
    this.regp = this.data.pattern || "^[a-zA-Z0-9 ñ.-_]+$"
    this.condition = new RegExp(this.regp);
    this.pattern = this.regp

    this.state = { value: "", errors: {}, };
  }
            /*let summernoteOptions = {        height: 300    }
            $('#summernote').summernote(summernoteOptions);
            $(document).on('click', '#change', function(){
            summernoteOptions.height = 100;
            let content = $('#summernote').summernote('code');
            $('#summernote').summernote('destroy');
            $('#summernote').summernote(summernoteOptions);
            $('#summernote').summernote('code', content);
            });*/
  componentDidMount=()=> {    this.fuLoadJs(this.data.id)  }
  fuLoadJs(stNomObj){
//https://summernote.org/deep-dive/    
    $(function () {
      $('#'+ stNomObj).summernote({
        //width: 350,focus: true,
        height: 400,
        codeviewIframeFilter: true,
        toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['fontText', ['fontname','fontsize','forecolor','fontsizeunit','color']],//['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
            ['para', ['style','ui','ul', 'ol', 'paragraph','height']],
            ['insert', ['link','unlink', 'picture', 'video','table','hr']],
            ['view', ['undo','fullscreen', 'codeview', 'help']],
        ]
        ,styleTags: [
            'p',
                { title: 'Blockquote', tag: 'blockquote', className: 'blockquote', value: 'blockquote' },
                'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'
            ]
        ,lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0']                  
        ,placeholder: 'Escriba aquí...'
      })
    })
  }
  handleChange = (name) => event => {
    let err = {};
    if (event.target.value.length < this.data.minLength) {
      err.msg = "Mínimo " + this.data.minLength + " caracteres";
    }
    else
      if (event.target.value.length > this.data.maxLength) {
        err.msg = "Máximo " + this.data.maxLength + " caracteres";
      }
      else {
        //const regp=  this.data.pattern || this.pattern
        //const condition = new RegExp(regp);
        let val = this.condition.test(event.target.value);
        //console.log("regp" + regp+ "==>"+ event.target.value + ":"+val)
        if (!val)
          err.msg = "Carácteres no válidos ";
      }
    this.setState({ value: event.target.value });
    this.setState({ errors: err }, () => {
      if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
        this.setState({ submitted: true });
      }
    });
  };
  //  <label className="col-sm-3 control-label" >{this.data.label}</label>
  //<HBoxMsg msg={this.state.errors.msg}/>
  render = () => {
    let col = Math.trunc((this.data.maxLength / 8) + .5)
    if (col > 10) col = 10
    let stCol = "col-md-" + col
    //console.log("Largo:",this.data.label, this.data.maxLength, "   col:", stCol)
    //onKeyPress={this.onKeyPress()}
    return (

      <div className="form-group row ">
        {this.data.label && (<label className="control-label col-md-2" >{this.data.label}</label>)}
        <div className={stCol} style={{width:'100%',height:'100%'}}>
          <textarea type="text"
            title={this.data.title}
            onChange={this.handleChange("value")}
            value={this.state.value || this.data.value || ""}
            disabled={this.data.disabled || false}
            id={this.data.id}
            name={this.data.name || this.data.id}
            style={this.data.style || { width: "750px" ,height:"300px"}}
            pattern={this.data.pattern || "[0-9-]$"}
            minLength={this.data.minLength || 0}
            maxLength={this.data.maxLength || 1000}
            className="resizable_textarea form-control"
            // rows={this.data.rows || 18}
            // cols={this.data.cols || 150}
            // size={this.data.size || 150}
          >
            {this.state.value || this.data.value || ""}
          </textarea>
        </div>
      </div>
    );
  }

}
export default HTextArea;