import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class TablaList extends HFormUser {
    componentDidMount() {  this.readApi(); }

    readApi() {
        this.post(Api.checkListTabla, { idKey: this.props.location.state.detKey.codigo })
            .then(data => { this.stateHarrys(this, data) })
    }
    startReCrear = iReg => { this.edit(0) };

    edit = row => {
        apiRuta.toRutaAdd(this, "Check", '/CheckList/TabCheckListTabEdit', {
            detKey: row
            , detHelp: this.props.location.state.detKey
        })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "codigo", label: "Código", align: "left" }
            , { id: "descrip", label: "descripcion", align: "left" }
            , { id: "bt_Asig", label: "Editar", align: "center", accion: this.edit, clsIcono: "fas fa-edit" }
        ]
        return (<ContainerRightTable
            tituloContainer={`Tabla ${this.props.location.state.detKey}`}
            tituloTable='Tabla'
            titulos={titulos}
            resObj={this.state.resObj}
            bt2Click={this.startReCrear}
            bt2Label="Agregar"

        />
        );
    }

}
export default WithNavigate(TablaList);