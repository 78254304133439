import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class TablaList extends HFormUser {

    componentDidMount() {  this.readApi(); }
    readApi() {
        this.post(Api.menuCheckListTabla)
            .then(data => { this.stateHarrys(this, data) })
    }
    edit = row => {
        apiRuta.toRutaAdd(this, "Check", '/CheckList/TabCheckListTab', { detKey: row })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        //if (this.state.swError) return (<ShowError msg={this.state.msg}/>)
        const titulos = [
            { id: "codigo", label: "Código", align: "left" }
            , { id: "descripcion", label: "Descripción", align: "left" }
            , { id: "nroreg", label: "Tot.Reg.", align: "right" }
            , { id: "bt_Asig", label: "Editar", align: "center", accion: this.edit, clsIcono: "fas fa-list" }
        ]
        return (<ContainerRightTable
            tituloContainer='Tablas de Ayuda(Help)'
            tituloTable='Tablas de Validación'
            titulos={titulos}
            resObj={this.state.resObj}
        />
        );
        //reCrear={this.startReCrear}
    }

}
export default WithNavigate(TablaList);