import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CheckObjChList extends HFormUser {
    constructor(props, stPagAB) {
        super(props);
        this.stPagAB = stPagAB;
        //console.log(this.stPagAB)
    }
    componentDidMount() {  this.readApi(); }
    readApi() {
        let stDataKey = { idKey: "0" ,filtro:this.stPagAB}
        if (this.props.location.state)
            stDataKey = { idKey: this.props.location.state.detKey
                         ,filtro:this.stPagAB
                        }
            this.post(Api.checkObjChList, stDataKey)
            .then(data => { this.stateHarrys(this, data) })
    }
    btChk = row => {
        //console.log(idReg)
        apiRuta.toRutaAdd(this, "Check", Api.frontCheckListDatChk, { detKey: row })
    };
    btEdit = row => {
        //console.log(idReg)
        apiRuta.toRutaAdd(this, "CheckEdit", Api.frontCheckListDat, { detKey: row })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "codigo", label: "Código", align: "right" }
            , { id: "numevento", label: "NE", align: "right" }
            , { id: "est_sigla", label: "Estado", align: "left" }
            , { id: "nombre", label: "Nombre", align: "left" }
            , { id: "codchk", label: "Check", align: "left" }
            , { id: "codtip", label: "Tabla", align: "left" }
            , { id: "estado", label: "Estado", align: "left" }
            , { id: "fprogra", label: "F.Programación", align: "left" }
            , { id: "bt_Check", label: "Check", align: "center", accion: this.btChk, clsIcono: "fas fa-check-circle" }
            , { id: "bt_Asig", label: "Editar", align: "center", accion: this.btEdit, clsIcono: "fas fa-edit" }
        ]
        return (<ContainerRightTable
            tituloContainer="Inspecciones"
            tituloTable='Inspecciones'
            titulos={titulos}
            resObj={this.state.resObj}
        />
        );
        //reCrear={this.startReCrear}
    }

}
export default CheckObjChList;