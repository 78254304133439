import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CheckFileList extends HFormUser {

    componentDidMount() { this.readApi(); }
    readApi() {
        let stDataKey = { idKey: "0" }
        if (this.props.location.state)
            stDataKey = { idKey: this.props.location.state.detKey }
            this.post(Api.checkFileList, stDataKey)
            .then(data => { this.stateHarrys(this, data) })
    }


    process = idReg => {
        this.post(Api.checkFileProcess, { idKey: idReg })
            .then(data => {
                this.stateHarrys(this, data)
                this.readApi();
            })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        //console.log("this.state.resObj",this.state.resObj)
        //if (this.state.swError) return (<ShowError msg={this.state.msg}/>)
        const titulos = [
            { id: "archivo", label: "Archivo", align: "left" }
            , { id: "sistema", label: "Sistema", align: "left" }
            , { id: "fecha", label: "F.Carga", align: "right" }
            , { id: "tot_reg", label: "T.Reg.", align: "right" }
            , { id: "tot_reg_err", label: "Reg.Err.", align: "right" }
            , { id: "tot_err", label: "T.Err.", align: "right" }
            , { id: "bt_Asig", label: "Editar", align: "center", accion: this.process }
        ]
        return (
            <ContainerRightTable
                tituloContainer="Inspecciones"
                tituloTable='Inspecciones'
                titulos={titulos}
                resObj={this.state.resObj}
            >
            </ContainerRightTable>
        );
        //reCrear={this.startReCrear}
    }

}
export default WithNavigate(CheckFileList);