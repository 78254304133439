import React, { Component } from "react";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxTree from "global/components/Page/InputHtml/HBoxTree/HBoxTree";

class ObjetoTree extends HFormUser {
    constructor(props, stPagAB) {
        super(props);
        this.stPagAB = stPagAB;
    }

    componentDidMount() {  this.readApi(); }
    readApi() {
        //console.log("ReadApi:",this.props.location.state.detKey)
        this.setState({ loading: true });
        this.post(Api.checkObjTreeLoad, { idKey: this.props.location.state.detKey.xrowid })
            .then(data => { this.stateHarrys(this, data) })
    }
    btCrear = (row) => {
        this.btnClickEdit({
            xrowid: -1
            , xrowidpadre: this.props.location.state.detKey.xrowid
            , nombre_padre: this.props.location.state.detKey.nombre
        })
    }
    btnClickEdit = (row, idReg) => {
        //console.log("row:::",row)
        apiRuta.toRutaAdd(this, "EdTree", Api.checkObjEditRoute, { detKey: row })
    }
    btnClickChild = (row, idReg) => {
        //console.log("row:",row)
        apiRuta.toRutaAdd(this, "AddTree", Api.checkObjEditRoute, {
            detKey: {
                xrowidpadre: row.xrowid
                , xrowid: -1
                , nombre: 'Nuevo'
                , nombre_padre: row.nombre
            }
        })
    }
    btnClickTree = (row, idReg) => {
        if (this.stPagAB == "A")
            apiRuta.toRutaAdd(this, "Obj.", Api.checkObjTreeBRoute, { detKey: row })
        else
            apiRuta.toRutaAdd(this, "Obj.", Api.checkObjTreeARoute, { detKey: row })

    }

    arrTit = [{ col: 'descripcion', label: 'Titulo' }
        , { col: 'por_ava', label: 'Por.', alig: 'right' }
        //,{col:'btnClickView',label:'Visualizar',accion:this.btnClickView}
        , { col: 'btnClickEdit', label: 'Ed', accion: this.btnClickEdit, width: 60, clsIcono: 'fa fa-edit', clsBoton: 'btn btn-default btn-xs' }
        // ,{col:'btnClickHtml',label:'Html',accion:this.btnClickHtml,width:60,clsIcono:'far fa-address-card',clsBoton:'btn btn-default btn-xs' }
        , { col: 'btnClickHijo', label: 'HH', accion: this.btnClickChild, width: 60, clsIcono: 'fa fa-plus-square', clsBoton: 'btn btn-default btn-xs' }
        //,{col:'btnClickDown',label:'Down',accion:this.btnClickDown,width:60,clsIcono:'far fa-hand-point-down',clsBoton:'btn btn-default btn-xs' }
        //,{col:'btnClickUp',label:'Up',accion:this.btnClickUp,width:60,clsIcono:'far fa-hand-point-up',clsBoton:'btn btn-default btn-xs' }
        //,{col:'btnClickUpNiv',label:'Niv+',accion:this.btnClickNivUp,width:60,clsIcono:'far fa-hand-point-left',clsBoton:'btn btn-default btn-xs' }
        //,{col:'btnClickUpNiv',label:'Niv-',accion:this.btnClickNivDown,width:60,clsIcono:'far fa-hand-point-right',clsBoton:'btn btn-default btn-xs' }
        , { col: 'btnClickTree', label: 'Tree', accion: this.btnClickTree, width: 60, clsIcono: 'fa fa-sitemap', clsBoton: 'btn btn-default btn-xs' }
    ]
    dragLine = (e) => {
        let visibleRows = e.component.getVisibleRows()
            , sourceNode = e.component.getNodeByKey(e.itemData.id)
            , targetNode = visibleRows[e.toIndex].node;

        //console.log("Drieccion",e.itemData.id,targetNode.data.id,sourceNode,targetNode)
        this.post(Api.checkObjDrag, { idSrc: e.itemData.id, idTrg: targetNode.data.id })
            .then(data => { this.readApi() })

    }
    render() {
        //console.log("this.state.resObj",this.state.resObj)
        if (this.state.loading) return (<ShowLoading />)
        return (<ContainerRightEdit key="right_edit"
            tituloContainer={this.props.location.state.detKey.nombre}
            contexto={this}
            bt1Click={this.btCrear}
            bt1Icon="fas fa-plus"
        >
            <HBoxTree key="KeyMat897"
                dataTree={this.state.resObj}
                dataTitulos={this.arrTit}
                expand={false}
                dragLine={this.dragLine}
            />
        </ContainerRightEdit>
        );
    }

}
export default ObjetoTree;