import Api from 'modCheckList/ApiCheckList';
const _menu =
{
    name: 'Dashboard',
    arMenu: [{
        titulo: "Inspecciones", icon: "fa fa-bug"
        , menNiv: [{ titulo: "Activas", ruta: Api.checkObjChActListRoute, icon: "fa fa-bug" }
            ,{ titulo: "Inspecciones", ruta: Api.checkObjChAllListRoute, icon: "fa fa-bug" }
        ]
    }
        , {
        titulo: "CheckList", icon: "fa fa-bug"
        , menNiv: [{ titulo: "Objetos", ruta: Api.checkObjListRoute, icon: "fa fa-bug" }
            , { titulo: "CheckList", ruta: Api.checkCheckListRoute, icon: "fa fa-bug" }
        ]
    }
        , {
        titulo: "Tablas", icon: "fa fa-bug"
        , menNiv: [{ titulo: "Ayudas", ruta: Api.checkGenTabHelpList, icon: "fa fa-bug" }
            , { titulo: "Tipos", ruta: Api.checkGenTipoList, icon: "fa fa-bug" }
            , { titulo: "Estados", ruta: Api.checkGenEstadoList, icon: "fa fa-bug" }
            , { titulo: "Empresas", ruta: Api.checkGenEmpersaList, icon: "fa fa-bug" }
        ]
    }
        , {
        titulo: "Interfaces ", icon: "fa fa-bug"
        , menNiv: [{ titulo: "Cargar Archivos", ruta: Api.checkFileLoad, icon: "fa fa-bug" }
            , { titulo: "Historial Carga", ruta: Api.checkFileList, icon: "fa fa-bug" }

        ]
    }
    ]
}




export default _menu
