/*const e_MovAcc = {
  EmpresaSin: 'SE',
  Empresa: 'EM',
}*/



const menuCheckListTabla = '/manten/menuCheckListTabla'
const checkListTabla = '/manten/checkListTabla'
const checkListTablaEdit = '/manten/checkListTablaEdit'
const checkListTablaAct = '/manten/checkListTablaAct'
const checkListTablaEli = '/manten/checkListTablaEli'

const checkCheckList = '/manten/CheckCheckList'
const checkCheckListRoute = '/manten/CheckCheckList'
const checkObjChList = '/manten/CheckObjChList'
const checkObjChActListRoute = '/manten/CheckObjChActList'
const checkObjChAllListRoute = '/manten/CheckObjChAllList'
const checkObjChEdit = '/manten/CheckObjChEdit'
const checkObjChEditList = '/manten/CheckObjChEditList'

const checkFileLoad = '/manten/CheckFileLoad'
const checkFileList = '/manten/CheckFileList'
const checkFileProcess = '/manten/CheckFileProcess'

const frontCheckListDat = '/CheckList/CheckListDat'
const frontCheckListDatChk = '/CheckList/CheckListDatChk'

const checkObjEditRoute = '/CheckList/CheckObjEdit'
const checkObjTreeARoute = '/CheckList/CheckObjTreeA'
const checkObjTreeBRoute = '/CheckList/CheckObjTreeB'
const checkObjTreeLoad = '/manten/CheckObjTreeLoad'
const checkObjListRoute = '/CheckList/CheckObjList'
const checkObjDrag  = '/manten/CheckObjDrag'
const checkObjList = '/manten/checkObjList'
const checkObjEdit = '/manten/checkObjEdit'
const checkObjAct = '/manten/checkObjAct'


const checkObjTipList = '/manten/checkObjTiplist'

const checkGenTabHelpList = '/CheckList/TabCheckList'
const checkGenTabHelpListTab = '/CheckList/TabCheckListTab'
const checkGenTabHelpListTabEdit = '/CheckList/TabCheckListTabEdit'

const checkGenTipoList = '/GenTipoList'
const checkGenEstadoList = '/GenEstadoList'
const checkGenEmpersaList = '/GenEmpresaList'

module.exports = {  
	checkGenEmpersaList,
	checkGenEstadoList,
	checkGenTipoList,
	checkGenTabHelpList,
    checkGenTabHelpListTab,
    checkGenTabHelpListTabEdit,

	menuCheckListTabla,
	checkListTabla,
	checkListTablaEdit,
	checkListTablaAct,
	checkListTablaEli,

	checkCheckList,
	checkCheckListRoute,
	checkObjChList,
	checkObjChActListRoute,
    checkObjChAllListRoute,
	checkObjChEdit,
    checkObjChEditList,

	checkFileLoad,
	checkFileList,
	checkFileProcess,

	frontCheckListDat,
    frontCheckListDatChk,
	checkObjTreeARoute,
	checkObjTreeBRoute,
	checkObjTreeLoad,
	checkObjEditRoute,
	checkObjListRoute,
	checkObjDrag,
	checkObjEdit,
	checkObjAct,
	checkObjList,

	checkObjTipList,
}
