import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import axios from 'axios';


import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";

import Api from "../ApiCheckList";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightError from "global/components/Page/ContainerRight/ContainerRightError";
import ShowLoading from "global/components/ShowLoading";
class CheckFileLoad extends HFormUser {


    onChangeHandler = event => {
        //console.log(event.target.files[0])
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    onClickHandler = () => {
        //console.log(Api.checkLoadFile)
        const aa = this.upLoadFile(Api.checkFileLoad)
            .then(data => {
                //console.log(">>>>>>>>>>",data)
                this.stateHarrys(this, data.data)
            })
    }
    onClickHandlerXX = () => {
        const data = new FormData()
        var dataFile = document.querySelector('#attachment');
        //console.log(dataFile.files[0])
        data.append('attachment', dataFile.files[0])
        axios.post(Api.CheckFileLoad + "/upload/checkFileLoad", data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(data => { this.stateHarrys(this, data) })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        //if (this.state.swError) return (<ShowError msg={this.state.msg}/>)
        return (
            <ContainerRightEdit>
                <div>
                    <h1>File Upload</h1>
                    <input type="file" class="btn btn-success btn-block custom-file-input"
                        name="attachment"
                        id="attachment"
                        onChange={this.onChangeHandler} />
                    <br />
                    <button type="button" class="btn btn-success btn-block" onClick={this.onClickHandler}>
                        Upload
                    </button>
                </div>
                <ContainerRightError data={this.state.resObj} />
            </ContainerRightEdit>
        );
    }

}
export default WithNavigate(CheckFileLoad);